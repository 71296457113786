import React from 'react'
import Layout from '../components/layout-v2';


const Page = () => (

  <Layout title="Contact us">
    <section className="section-contact">



      <h4>Get in touch</h4>
      <section className="row distributor">

        <div className="col-md-4 col-xs-12">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">India - Headquarter</h5>
              <p className="card-text">
                <strong>Quantic Cloud Technologies Pvt. Ltd.</strong><br/>
                {/*
                <span data-translate="ADD-PARAKH-LINE1">89, Swasthya Nagar</span>,<br/>
                <span data-translate="ADD-PARAKH-LINE3">Indore, MP 452010</span>,<br/>
Regus, Brilliant Solitaire
6th Floor, Brilliant Solitaire,
Scheme 78, Part II,
Vijay Nagar, Indore - 452010

https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1839.4924183483586!2d75.87438197599377!3d22.76594399625262!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMjLCsDQ1JzU3LjQiTiA3NcKwNTInMzEuNyJF!5e0!3m2!1sen!2sin!4v1543994498233

https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1839.4924183483586!2d75.8827425!3d22.7640614!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMjLCsDQ1JzU3LjQiTiA3NcKwNTInMzEuNyJF!5e0!3m2!1sen!2sin!4v1543994498233
https://www.google.com/maps/search/6th+Floor,+Brilliant+Solitaire,+Indore/@22.7640614,75.8827425,19z

<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d919.760204649553!2d75.88216732924035!3d22.763866399067783!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396302bb8172c819%3A0xdb101c612543b3c7!2sBrilliant+Solitaire%2C+Scheme+Number+78%2C+Part+II%2C+Vijay+Nagar%2C+Indore%2C+Madhya+Pradesh+452010!5e0!3m2!1sen!2sin!4v1551175136805" width="600" height="450" frameborder="0" style="border:0" allowfullscreen></iframe>
                */}
                <span>2nd Floor, Winway World Offices</span>,<br/>
                <span>Indore, MP 452010</span>,<br/>
                <span>India</span><br/>
                <span className="fa fa-envelope fa-fw"></span><span data-translate="EMAIL">E-mail</span>:
                <a href="mailto:hello@quantic.co.in">&nbsp;hello@quantic.co.in</a><br/>
                <abbr title="Mobile Phone"><span className="fa fa-mobile fa-fw"></span>P:</abbr> <a href="tel:+91731">(+91) (731) 494-9434 </a><br/>
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14514.643334276776!2d75.89834114420259!3d22.75356978265498!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xcb087303028f5d06!2sQuantic%20Cloud%20Technologies%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1671080770682!5m2!1sen!2sin" frameborder="0" style={{border:0}} allowFullScreen></iframe>
              </p>
            </div>
          </div>

        </div>

        <div className="col-md-4 col-xs-12">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">US - R&D Center</h5>
              <p className="card-text">
                <strong>Quantic</strong><br/>
                <span>11836 Water Run Alley</span>,<br/>
                <span>Windermere, FL 34786</span>,<br/>
                <span>USA</span><br/>
                <span className="fa fa-envelope fa-fw"></span><span>E-mail</span>:
                <a href="mailto:it-support@quantic.co.in">&nbsp;it-support@quantic.co.in</a><br/>
                <abbr title="Mobile Phone"><span className="fa fa-mobile fa-fw"></span>M:</abbr> <a href="tel:+13214228685">(+1) (321) 422-8685 </a><br/>

                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3507.8664863299564!2d-81.56975254882346!3d28.45344088240287!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e780fc6150d5f3%3A0xbfc68e8219528974!2s11836+Water+Run+Alley%2C+Windermere%2C+FL+34786%2C+USA!5e0!3m2!1sen!2sin!4v1559105212385!5m2!1sen!2sin"
                        frameborder="0" style={{border:0}} allowFullScreen></iframe>
              </p>
            </div>
          </div>
        </div>

      </section>

    </section>
  </Layout>
);

export default Page;

/*
<!--  विक्रेता -->
<!-- 21.831883, 75.610982 -->
                <!--
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1180.301852876474!2d75.6104329881298!3d21.831895844012994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd885d195d53493%3A0x152048c4ac5e3d3e!2sParakh+Shikshan+Sewa+Sanstha!5e1!3m2!1sen!2sin!4v1515244029383"
                        frameborder="0" style="border:0" allowFullScreen></iframe>
                -->

<!-- 22.5960551,75.2938195 -->
 */

